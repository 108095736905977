<template>
  <div class="view-box fill-height">
    <div class="user-info d-flex justify-space-between">
      <div class="d-flex">
        <Thumbnail
          :src="currentContact.thumbnail"
          size="49px"
          :username="currentContact.name"
          :status="currentContact.availability_status"
        />
        <div style="margin-left: 7px">
          <div class="client_name">{{ currentContact.name }}</div>
          <div class="client_email" v-if="currentContact.email">
            <svg
              style="margin-bottom: -3px;"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="icon--font mail"
            >
              <path
                d="M5.25 4h13.5a3.25 3.25 0 0 1 3.245 3.066L22 7.25v9.5a3.25 3.25 0 0 1-3.066 3.245L18.75 20H5.25a3.25 3.25 0 0 1-3.245-3.066L2 16.75v-9.5a3.25 3.25 0 0 1 3.066-3.245L5.25 4h13.5-13.5ZM20.5 9.373l-8.15 4.29a.75.75 0 0 1-.603.043l-.096-.042L3.5 9.374v7.376a1.75 1.75 0 0 0 1.606 1.744l.144.006h13.5a1.75 1.75 0 0 0 1.744-1.607l.006-.143V9.373ZM18.75 5.5H5.25a1.75 1.75 0 0 0-1.744 1.606L3.5 7.25v.429l8.5 4.473 8.5-4.474V7.25a1.75 1.75 0 0 0-1.607-1.744L18.75 5.5Z"
                fill="currentColor"
              ></path>
            </svg>
            {{ currentContact.email }}
          </div>
<!--          <div class="client_email" v-if="!currentContact.email">
            <svg
              style="margin-bottom: -3px;"
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="icon&#45;&#45;font mail"
            >
              <path
                d="M5.25 4h13.5a3.25 3.25 0 0 1 3.245 3.066L22 7.25v9.5a3.25 3.25 0 0 1-3.066 3.245L18.75 20H5.25a3.25 3.25 0 0 1-3.245-3.066L2 16.75v-9.5a3.25 3.25 0 0 1 3.066-3.245L5.25 4h13.5-13.5ZM20.5 9.373l-8.15 4.29a.75.75 0 0 1-.603.043l-.096-.042L3.5 9.374v7.376a1.75 1.75 0 0 0 1.606 1.744l.144.006h13.5a1.75 1.75 0 0 0 1.744-1.607l.006-.143V9.373ZM18.75 5.5H5.25a1.75 1.75 0 0 0-1.744 1.606L3.5 7.25v.429l8.5 4.473 8.5-4.474V7.25a1.75 1.75 0 0 0-1.607-1.744L18.75 5.5Z"
                fill="currentColor"
              ></path>
            </svg>
            Not Available
          </div>-->
<!--          <div class="client_email" v-if="!currentContact.phone_number">
            <svg
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="icon&#45;&#45;font call"
            >
              <path
                d="m7.056 2.418 1.167-.351a2.75 2.75 0 0 1 3.302 1.505l.902 2.006a2.75 2.75 0 0 1-.633 3.139L10.3 10.11a.25.25 0 0 0-.078.155c-.044.397.225 1.17.845 2.245.451.781.86 1.33 1.207 1.637.242.215.375.261.432.245l2.01-.615a2.75 2.75 0 0 1 3.034 1.02l1.281 1.776a2.75 2.75 0 0 1-.339 3.605l-.886.84a3.75 3.75 0 0 1-3.587.889c-2.754-.769-5.223-3.093-7.435-6.924-2.215-3.836-2.992-7.14-2.276-9.913a3.75 3.75 0 0 1 2.548-2.652Zm.433 1.437a2.25 2.25 0 0 0-1.529 1.59c-.602 2.332.087 5.261 2.123 8.788 2.033 3.522 4.222 5.582 6.54 6.23a2.25 2.25 0 0 0 2.151-.534l.887-.84a1.25 1.25 0 0 0 .154-1.639l-1.28-1.775a1.25 1.25 0 0 0-1.38-.464l-2.015.617c-1.17.348-2.232-.593-3.372-2.568C9 11.93 8.642 10.9 8.731 10.099c.047-.416.24-.8.546-1.086l1.494-1.393a1.25 1.25 0 0 0 .288-1.427l-.902-2.006a1.25 1.25 0 0 0-1.5-.684l-1.168.352Z"
                fill="currentColor"
              ></path>
            </svg>
            Not Available
          </div>-->
          <div class="client_phone" v-if="currentContact.phone_number">
            <svg
              width="14"
              height="14"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              class="icon--font call"
            >
              <path
                d="m7.056 2.418 1.167-.351a2.75 2.75 0 0 1 3.302 1.505l.902 2.006a2.75 2.75 0 0 1-.633 3.139L10.3 10.11a.25.25 0 0 0-.078.155c-.044.397.225 1.17.845 2.245.451.781.86 1.33 1.207 1.637.242.215.375.261.432.245l2.01-.615a2.75 2.75 0 0 1 3.034 1.02l1.281 1.776a2.75 2.75 0 0 1-.339 3.605l-.886.84a3.75 3.75 0 0 1-3.587.889c-2.754-.769-5.223-3.093-7.435-6.924-2.215-3.836-2.992-7.14-2.276-9.913a3.75 3.75 0 0 1 2.548-2.652Zm.433 1.437a2.25 2.25 0 0 0-1.529 1.59c-.602 2.332.087 5.261 2.123 8.788 2.033 3.522 4.222 5.582 6.54 6.23a2.25 2.25 0 0 0 2.151-.534l.887-.84a1.25 1.25 0 0 0 .154-1.639l-1.28-1.775a1.25 1.25 0 0 0-1.38-.464l-2.015.617c-1.17.348-2.232-.593-3.372-2.568C9 11.93 8.642 10.9 8.731 10.099c.047-.416.24-.8.546-1.086l1.494-1.393a1.25 1.25 0 0 0 .288-1.427l-.902-2.006a1.25 1.25 0 0 0-1.5-.684l-1.168.352Z"
                fill="currentColor"
              ></path>
            </svg>
            {{ currentContact.phone_number }}
          </div>

          <div class="d-flex" style="margin-top: 2px;">
<!--            <router-link :to="{name: 'inbox_conversation',params: { conversation_id: 154 },}" style="margin-right: 0.5rem;">
              <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="url(#paint0_radial_8007_111069)"/>
                <path d="M12.0026 5.27997C8.6211 5.27997 5.99902 7.75795 5.99902 11.1035C5.99902 12.8535 6.71645 14.3664 7.88415 15.411C7.98171 15.4981 8.04175 15.6212 8.04475 15.7532L8.07777 16.8219C8.08017 16.9005 8.10186 16.9774 8.14091 17.0456C8.17996 17.1139 8.23519 17.1716 8.30174 17.2135C8.36829 17.2555 8.44412 17.2804 8.52258 17.2862C8.60103 17.292 8.6797 17.2784 8.75167 17.2466L9.94339 16.7213C10.0439 16.6763 10.158 16.6688 10.2646 16.6973C10.8124 16.8474 11.3948 16.9285 12.0026 16.9285C15.3842 16.9285 18.0062 14.4505 18.0062 11.105C18.0062 7.75945 15.3842 5.27997 12.0026 5.27997Z" fill="white"/>
                <path d="M8.39746 12.807L10.161 10.0093C10.2274 9.90397 10.3148 9.81354 10.4179 9.74371C10.5209 9.67389 10.6373 9.62619 10.7598 9.60362C10.8822 9.58106 11.008 9.58412 11.1291 9.61261C11.2503 9.6411 11.3643 9.69441 11.4638 9.76916L12.8671 10.8213C12.9298 10.8682 13.006 10.8934 13.0843 10.8931C13.1625 10.8929 13.2386 10.8671 13.3009 10.8198L15.195 9.38193C15.4472 9.18981 15.7774 9.49299 15.6093 9.76165L13.8442 12.5578C13.7779 12.6632 13.6904 12.7536 13.5874 12.8234C13.4843 12.8932 13.3679 12.9409 13.2455 12.9635C13.1231 12.9861 12.9973 12.983 12.8761 12.9545C12.7549 12.926 12.641 12.8727 12.5414 12.798L11.1381 11.7458C11.0755 11.6989 10.9992 11.6737 10.921 11.674C10.8427 11.6743 10.7667 11.7 10.7043 11.7473L8.81021 13.1852C8.55806 13.3773 8.22786 13.0756 8.39746 12.807Z" fill="url(#paint1_radial_8007_111069)"/>
                <defs>
                  <radialGradient id="paint0_radial_8007_111069" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.02 23.9993) scale(26.4 26.3993)">
                    <stop stop-color="#0099FF"/>
                    <stop offset="0.6" stop-color="#A033FF"/>
                    <stop offset="0.9" stop-color="#FF5280"/>
                    <stop offset="1" stop-color="#FF7061"/>
                  </radialGradient>
                  <radialGradient id="paint1_radial_8007_111069" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8.01023 17.2872) scale(13.2079 13.2079)">
                    <stop stop-color="#0099FF"/>
                    <stop offset="0.6" stop-color="#A033FF"/>
                    <stop offset="0.9" stop-color="#FF5280"/>
                    <stop offset="1" stop-color="#FF7061"/>
                  </radialGradient>
                </defs>
              </svg>

            </router-link>-->
            <router-link :to="{name: 'inbox_conversation',params: { conversation_id: 195 },}" style="margin-right: 0.5rem;">
              <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#00D95F"/>
                <path d="M6.00006 17.5557L6.86103 14.3544C6.16668 13.1073 5.94608 11.6521 6.23971 10.2558C6.53333 8.85943 7.3215 7.61562 8.45957 6.75261C9.59764 5.88959 11.0093 5.46525 12.4354 5.55744C13.8616 5.64964 15.2066 6.25219 16.2237 7.25453C17.2408 8.25687 17.8617 9.59176 17.9725 11.0143C18.0833 12.4367 17.6765 13.8514 16.8269 14.9987C15.9772 16.1459 14.7417 16.9488 13.3469 17.26C11.9522 17.5712 10.4918 17.3698 9.23379 16.6928L6.00006 17.5557ZM9.38972 15.4948L9.58971 15.6133C10.5009 16.1525 11.5652 16.3756 12.6167 16.2479C13.6682 16.1202 14.6478 15.6488 15.403 14.9072C16.1581 14.1656 16.6463 13.1955 16.7915 12.148C16.9367 11.1005 16.7307 10.0344 16.2056 9.11598C15.6806 8.19754 14.866 7.47829 13.8887 7.07032C12.9115 6.66235 11.8265 6.58857 10.8029 6.86048C9.77925 7.13239 8.87446 7.73472 8.22952 8.5736C7.58458 9.41248 7.23571 10.4408 7.23729 11.4983C7.23643 12.3751 7.47933 13.235 7.93894 13.9822L8.06436 14.1886L7.58303 15.9754L9.38972 15.4948Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2436 12.3203C14.1264 12.2259 13.9891 12.1594 13.8422 12.126C13.6954 12.0926 13.5428 12.0931 13.3962 12.1274C13.1759 12.2188 13.0335 12.5639 12.8911 12.7365C12.8611 12.7779 12.817 12.8069 12.7671 12.8181C12.7172 12.8293 12.6648 12.822 12.62 12.7974C11.8133 12.482 11.1372 11.9036 10.7014 11.1562C10.6642 11.1095 10.6467 11.0503 10.6523 10.991C10.658 10.9316 10.6866 10.8768 10.7319 10.8381C10.8907 10.6811 11.0073 10.4868 11.0709 10.2729C11.085 10.0371 11.0309 9.80214 10.915 9.59613C10.8254 9.30732 10.6548 9.05016 10.4235 8.85503C10.3042 8.80145 10.1719 8.78349 10.0425 8.80331C9.91323 8.82312 9.79242 8.87987 9.69469 8.9667C9.52503 9.11284 9.39036 9.29511 9.30061 9.50009C9.21085 9.70507 9.1683 9.92754 9.17607 10.1511C9.1766 10.2767 9.19253 10.4017 9.22353 10.5234C9.30224 10.8157 9.42328 11.095 9.58283 11.3524C9.69794 11.5496 9.82354 11.7406 9.95908 11.9244C10.3996 12.5281 10.9533 13.0406 11.5895 13.4336C11.9088 13.6333 12.25 13.7957 12.6064 13.9175C12.9767 14.0851 13.3855 14.1494 13.7894 14.1037C14.0195 14.0689 14.2376 13.9782 14.4244 13.8395C14.6112 13.7008 14.7609 13.5185 14.8605 13.3084C14.919 13.1815 14.9368 13.0397 14.9114 12.9023C14.8504 12.6215 14.4741 12.4556 14.2436 12.3203Z" fill="white"/>
              </svg>

            </router-link>
            <router-link :to="{name: 'inbox_conversation',params: { conversation_id: 194 },}">
              <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" fill="#483A9D"/>
                <path d="M12 18.2709C8.54082 18.2709 5.72916 15.4592 5.72916 12C5.72916 8.54085 8.54082 5.72919 12 5.72919C15.4592 5.72919 18.2708 8.54085 18.2708 12C18.2708 12.2392 18.0725 12.4375 17.8333 12.4375C17.5942 12.4375 17.3958 12.2392 17.3958 12C17.3958 9.02502 14.975 6.60419 12 6.60419C9.02499 6.60419 6.60416 9.02502 6.60416 12C6.60416 14.975 9.02499 17.3959 12 17.3959C12.2392 17.3959 12.4375 17.5942 12.4375 17.8334C12.4375 18.0725 12.2392 18.2709 12 18.2709Z" fill="white"/>
                <path d="M10.2501 17.6875H9.6668C9.42763 17.6875 9.2293 17.4892 9.2293 17.25C9.2293 17.0108 9.41597 16.8183 9.65514 16.8125C8.7393 13.6858 8.7393 10.3142 9.65514 7.1875C9.4218 7.18167 9.2293 6.98917 9.2293 6.75C9.2293 6.51083 9.42763 6.3125 9.6668 6.3125H10.2501C10.3901 6.3125 10.5243 6.3825 10.606 6.49333C10.6876 6.61 10.711 6.75583 10.6643 6.88999C9.56763 10.1858 9.56763 13.82 10.6643 17.1158C10.711 17.25 10.6876 17.3958 10.606 17.5125C10.5243 17.6292 10.3901 17.6875 10.2501 17.6875Z" fill="white"/>
                <path d="M14.6016 12.4375C14.3625 12.4375 14.1641 12.2392 14.1641 12C14.1641 10.2617 13.8841 8.54087 13.3358 6.89003C13.26 6.66253 13.3825 6.41169 13.61 6.33586C13.8375 6.26003 14.0883 6.38255 14.1641 6.61005C14.7416 8.34838 15.0391 10.1625 15.0391 12C15.0391 12.2392 14.8408 12.4375 14.6016 12.4375Z" fill="white"/>
                <path d="M12 15.0392C10.3667 15.0392 8.75083 14.8059 7.1875 14.345C7.18167 14.5784 6.98917 14.7709 6.75 14.7709C6.51083 14.7709 6.3125 14.5725 6.3125 14.3334V13.75C6.3125 13.61 6.3825 13.4759 6.49333 13.3942C6.60417 13.3125 6.75583 13.2892 6.88999 13.3359C8.54083 13.8842 10.2617 14.1642 12 14.1642C12.2392 14.1642 12.4375 14.3625 12.4375 14.6017C12.4375 14.8409 12.2392 15.0392 12 15.0392Z" fill="white"/>
                <path d="M17.2501 10.6875C17.2034 10.6875 17.1568 10.6817 17.1101 10.6642C13.8143 9.56753 10.1801 9.56753 6.88427 10.6642C6.65677 10.74 6.40593 10.6175 6.33009 10.39C6.25426 10.1625 6.37676 9.91167 6.60426 9.83584C10.0809 8.675 13.9076 8.675 17.3784 9.83584C17.6059 9.91167 17.7284 10.1625 17.6526 10.39C17.6059 10.5708 17.4309 10.6875 17.2501 10.6875Z" fill="white"/>
                <path d="M15.6517 18.2708C14.205 18.2708 13.0325 17.0925 13.0325 15.6517C13.0325 14.2108 14.2108 13.0267 15.6517 13.0267C15.9433 13.0267 16.235 13.0675 16.5092 13.155C16.7425 13.225 16.8708 13.47 16.8008 13.7033C16.7308 13.9367 16.4858 14.065 16.2525 13.995C16.06 13.9367 15.8558 13.9075 15.6517 13.9075C14.6892 13.9075 13.9075 14.6892 13.9075 15.6575C13.9075 16.6258 14.6892 17.4017 15.6517 17.4017C16.6142 17.4017 17.3958 16.62 17.3958 15.6575C17.3958 15.3075 17.2967 14.975 17.1042 14.6892C16.97 14.4908 17.0225 14.2167 17.2267 14.0825C17.425 13.9483 17.6992 14.0009 17.8333 14.205C18.125 14.6367 18.2767 15.1442 18.2767 15.6634C18.2708 17.0925 17.0925 18.2708 15.6517 18.2708Z" fill="white"/>
                <path d="M16.6901 14.0709C16.5676 14.0709 16.4451 14.0184 16.3576 13.9192L15.6285 13.085C15.471 12.9042 15.4885 12.6242 15.6693 12.4667C15.8501 12.3092 16.1243 12.3267 16.2876 12.5076L17.0168 13.3417C17.1743 13.5225 17.1568 13.8025 16.976 13.96C16.8943 14.0359 16.7951 14.0709 16.6901 14.0709Z" fill="white"/>
                <path d="M15.8383 14.6892C15.7042 14.6892 15.57 14.625 15.4825 14.5084C15.3425 14.3159 15.3833 14.0417 15.5758 13.8959L16.4275 13.2775C16.62 13.1317 16.8942 13.1784 17.04 13.3709C17.18 13.5634 17.1392 13.8376 16.9467 13.9834L16.095 14.6017C16.0192 14.6601 15.9317 14.6892 15.8383 14.6892Z" fill="white"/>
              </svg>
            </router-link>

          </div>
        </div>
      </div>
      <div class="d-flex">
        <svg
          v-if="shouldShowMergeIcon"
          v-tooltip="$t('CONTACT_PANEL.MERGE_CONVERSATIONS')"
          @click="toggleGlobalMerge"
          class="cursor-pointer custom-margin"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 10.75C4.69036 10.75 5.25 11.3096 5.25 12C5.25 12.6904 4.69036 13.25 4 13.25C3.30964 13.25 2.75 12.6904 2.75 12C2.75 11.3096 3.30964 10.75 4 10.75ZM6.75 12C6.75 10.4812 5.51878 9.25 4 9.25C2.48122 9.25 1.25 10.4812 1.25 12C1.25 13.5188 2.48122 14.75 4 14.75C5.51878 14.75 6.75 13.5188 6.75 12Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2.75C4.69036 2.75 5.25 3.30964 5.25 4C5.25 4.69036 4.69036 5.25 4 5.25C3.30964 5.25 2.75 4.69036 2.75 4C2.75 3.30964 3.30964 2.75 4 2.75ZM6.75 4C6.75 2.48122 5.51878 1.25 4 1.25C2.48122 1.25 1.25 2.48122 1.25 4C1.25 5.51878 2.48122 6.75 4 6.75C5.51878 6.75 6.75 5.51878 6.75 4Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4 18.75C4.69036 18.75 5.25 19.3096 5.25 20C5.25 20.6904 4.69036 21.25 4 21.25C3.30964 21.25 2.75 20.6904 2.75 20C2.75 19.3096 3.30964 18.75 4 18.75ZM6.75 20C6.75 18.4812 5.51878 17.25 4 17.25C2.48122 17.25 1.25 18.4812 1.25 20C1.25 21.5188 2.48122 22.75 4 22.75C5.51878 22.75 6.75 21.5188 6.75 20Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L6 12.75C5.58579 12.75 5.25 12.4142 5.25 12C5.25 11.5858 5.58579 11.25 6 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7803 4.21967C12.0901 3.52941 11.1156 3.25 10 3.25H6C5.58579 3.25 5.25 3.58579 5.25 4C5.25 4.41421 5.58579 4.75 6 4.75H10C10.8844 4.75 11.4099 4.97059 11.7197 5.28033C12.0294 5.59007 12.25 6.11561 12.25 7V17C12.25 17.8844 12.0294 18.4099 11.7197 18.7197C11.4099 19.0294 10.8844 19.25 10 19.25H6C5.58579 19.25 5.25 19.5858 5.25 20C5.25 20.4142 5.58579 20.75 6 20.75H10C11.1156 20.75 12.0901 20.4706 12.7803 19.7803C13.4706 19.0901 13.75 18.1156 13.75 17V7C13.75 5.88439 13.4706 4.90993 12.7803 4.21967Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4415 9.49947C18.7179 9.19099 19.1921 9.16502 19.5005 9.44146L21.3378 11.0879C21.8874 11.5804 21.8874 12.4196 21.3378 12.9121L19.5005 14.5585C19.1921 14.835 18.7179 14.809 18.4415 14.5005C18.165 14.1921 18.191 13.7179 18.4995 13.4415L20.108 12L18.4995 10.5585C18.191 10.2821 18.165 9.80794 18.4415 9.49947Z" fill="#292D32"/>
        </svg>
        <svg
          v-if="!shouldShowMergeIcon"
          v-tooltip="$t('CONTACT_PANEL.UNMERGE_CONVERSATIONS')"
          class="cursor-pointer custom-margin"
          @click="toggleGlobalUnmerge"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.30853 9.24947C5.03209 8.941 4.55793 8.91503 4.24946 9.19147L2.41219 10.8379C1.86259 11.3305 1.86259 12.1696 2.41219 12.6621L4.24946 14.3085C4.55793 14.585 5.03209 14.559 5.30853 14.2505C5.58497 13.9421 5.559 13.4679 5.25053 13.1915L3.64202 11.75L5.25053 10.3085C5.559 10.0321 5.58497 9.55795 5.30853 9.24947Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.30853 17.2495C5.03209 16.941 4.55793 16.915 4.24946 17.1915L2.41219 18.8379C1.86259 19.3305 1.86259 20.1696 2.41219 20.6621L4.24946 22.3085C4.55793 22.585 5.03209 22.559 5.30853 22.2505C5.58497 21.9421 5.559 21.4679 5.25053 21.1915L3.64202 19.75L5.25053 18.3085C5.559 18.0321 5.58497 17.5579 5.30853 17.2495Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 12C18.75 12.4142 18.4142 12.75 18 12.75L4 12.75C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25L18 11.25C18.4142 11.25 18.75 11.5858 18.75 12Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7803 4.21967C10.0901 3.52941 9.11561 3.25 8 3.25H4C3.58579 3.25 3.25 3.58579 3.25 4C3.25 4.41421 3.58579 4.75 4 4.75H8C8.88439 4.75 9.40993 4.97059 9.71967 5.28033C10.0294 5.59007 10.25 6.11561 10.25 7V17C10.25 17.8844 10.0294 18.4099 9.71967 18.7197C9.40993 19.0294 8.88439 19.25 8 19.25H4C3.58579 19.25 3.25 19.5858 3.25 20C3.25 20.4142 3.58579 20.75 4 20.75H8C9.11561 20.75 10.0901 20.4706 10.7803 19.7803C11.4706 19.0901 11.75 18.1156 11.75 17V7C11.75 5.88439 11.4706 4.90993 10.7803 4.21967Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.75 10.5C21.4404 10.5 22 11.0596 22 11.75C22 12.4404 21.4404 13 20.75 13C20.0596 13 19.5 12.4404 19.5 11.75C19.5 11.0596 20.0596 10.5 20.75 10.5ZM23.5 11.75C23.5 10.2312 22.2688 9 20.75 9C19.2312 9 18 10.2312 18 11.75C18 13.2688 19.2312 14.5 20.75 14.5C22.2688 14.5 23.5 13.2688 23.5 11.75Z" fill="#292D32"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.30853 1.24947C5.03209 0.941 4.55793 0.915031 4.24946 1.19147L2.41219 2.83793C1.86259 3.33046 1.86259 4.16956 2.41219 4.66208L4.24946 6.30855C4.55793 6.58498 5.03209 6.55901 5.30853 6.25054C5.58497 5.94207 5.559 5.46791 5.25053 5.19147L3.64202 3.75001L5.25053 2.30855C5.559 2.03211 5.58497 1.55795 5.30853 1.24947Z" fill="#292D32"/>
        </svg>
        <svg
          v-if="!currentChat.muted"
          @click="mute"
          class="cursor-pointer custom-margin"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 10V14C2 16 3 17 5 17H6.43C6.8 17 7.17 17.11 7.49 17.3L10.41 19.13C12.93 20.71 15 19.56 15 16.59V7.41003C15 4.43003 12.93 3.29003 10.41 4.87003L7.49 6.70003C7.17 6.89003 6.8 7.00003 6.43 7.00003H5C3 7.00003 2 8.00003 2 10Z"
            stroke="black"
            stroke-width="1.5"
          />
          <path
            d="M18 8C19.78 10.37 19.78 13.63 18 16"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.8301 5.5C22.7201 9.35 22.7201 14.65 19.8301 18.5"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-if="currentChat.muted"
          @click="unmute"
          class="cursor-pointer custom-margin"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 8.37V7.41C15 4.43 12.93 3.29 10.41 4.87L7.49 6.7C7.17 6.89 6.8 7 6.43 7H5C3 7 2 8 2 10V14C2 16 3 17 5 17H7"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.41 19.13C12.93 20.71 15 19.56 15 16.59V12.95"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.81 9.42C19.71 11.57 19.44 14.08 18 16"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.15 7.8C22.62 11.29 22.18 15.37 19.83 18.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22 2L2 22"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          @click="toggleEmailActionsModal"
          v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
          class="cursor-pointer custom-transcriptm mr-3"
          width="21"
          height="21"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.93217 3.93217C5.15414 2.7102 6.97603 2.25 9.3 2.25H11.1C11.5142 2.25 11.85 2.58579 11.85 3C11.85 3.41421 11.5142 3.75 11.1 3.75H9.3C7.12397 3.75 5.79586 4.1898 4.99283 4.99283C4.1898 5.79586 3.75 7.12397 3.75 9.3V14.7C3.75 16.876 4.1898 18.2041 4.99283 19.0072C5.79586 19.8102 7.12397 20.25 9.3 20.25H14.7C16.876 20.25 18.2041 19.8102 19.0072 19.0072C19.8102 18.2041 20.25 16.876 20.25 14.7V12.9C20.25 12.4858 20.5858 12.15 21 12.15C21.4142 12.15 21.75 12.4858 21.75 12.9V14.7C21.75 17.024 21.2898 18.8459 20.0678 20.0678C18.8459 21.2898 17.024 21.75 14.7 21.75H9.3C6.97603 21.75 5.15414 21.2898 3.93217 20.0678C2.7102 18.8459 2.25 17.024 2.25 14.7V9.3C2.25 6.97603 2.7102 5.15414 3.93217 3.93217Z"
            fill="#292D32"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.3514 2.70082C17.0391 2.97295 17.0065 3.4467 17.2786 3.75899L19.8387 6.6969L16.9008 9.25694C16.5885 9.52906 16.5559 10.0028 16.828 10.3151C17.1002 10.6274 17.5739 10.66 17.8862 10.3878L21.955 6.84235L18.4095 2.77355C18.1374 2.46126 17.6637 2.4287 17.3514 2.70082Z"
            fill="#292D32"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.4168 16.9536C7.67036 11.688 12.0919 6.91623 18.223 7.33762L20.8454 7.51785C21.2587 7.54625 21.6167 7.23428 21.6451 6.82104C21.6735 6.4078 21.3615 6.04978 20.9483 6.02138L18.3258 5.84115C11.3447 5.36134 6.03738 10.8557 6.93164 17.1642C6.98978 17.5743 7.36937 17.8596 7.77949 17.8015C8.1896 17.7433 8.47493 17.3637 8.4168 16.9536Z"
            fill="#292D32"
          />
        </svg>
        <resolve-action
          :conversation-id="currentChat.id"
          :status="currentChat.status"
        />
        <email-transcript-modal
          v-if="showEmailActionsModal"
          :show="showEmailActionsModal"
          :current-chat="currentChat"
          @cancel="toggleEmailActionsModal"
        />
      </div>
    </div>
    <div class="sidebar-toggle__wrap">
      <woot-button
        variant="smooth"
        size="tiny"
        color-scheme="secondary"
        class="sidebar-toggle--button"
        :icon="isRightOrLeftIcon"
        @click="onToggleContactPanel"
      />
    </div>
    <ul class="conversation-panel">
      <transition name="slide-up">
        <li class="spinner--container">
          <span v-if="shouldShowSpinner" class="spinner message" />
        </li>
      </transition>
      <message
        v-for="message in getReadMessages"
        :key="message.id"
        class="message--read"
        data-clarity-mask="True"
        :data="message"
        :is-a-tweet="isATweet"
        :is-a-whatsapp-channel="isAWhatsAppChannel"
        :has-instagram-story="hasInstagramStory"
        :is-web-widget-inbox="isAWebWidgetInbox"
        :inboxes-list="inboxesList"
      />
      <li v-show="getUnreadCount != 0" class="unread--toast">
        <span class="text-uppercase">
          {{ getUnreadCount }}
          {{
            getUnreadCount > 1
              ? $t('CONVERSATION.UNREAD_MESSAGES')
              : $t('CONVERSATION.UNREAD_MESSAGE')
          }}
        </span>
      </li>
      <message
        v-for="message in getUnReadMessages"
        :key="message.id"
        class="message--unread"
        data-clarity-mask="True"
        :data="message"
        :is-a-tweet="isATweet"
        :is-a-whatsapp-channel="isAWhatsAppChannel"
        :has-instagram-story="hasInstagramStory"
        :is-web-widget-inbox="isAWebWidgetInbox"
        :inboxes-list="inboxesList"
      />
    </ul>
    <div
      class="conversation-footer"
      :class="{ 'modal-mask': isPopoutReplyBox }"
    >
      <div v-if="isAnyoneTyping" class="typing-indicator-wrap">
        <div class="typing-indicator">
          {{ typingUserNames }}
          <img
            class="gif"
            src="~dashboard/assets/images/typing.gif"
            alt="Someone is typing"
          />
        </div>
      </div>
      <reply-box
        :conversation-id="currentChat.id"
        :is-a-tweet="isATweet"
        :selected-tweet="selectedTweet"
        :popout-reply-box.sync="isPopoutReplyBox"
        @click="showPopoutReplyBox"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ReplyBox from './ReplyBox';
import Message from './Message';
import conversationMixin, {
  filterDuplicateSourceMessages,
} from '../../../mixins/conversations';
import Banner from 'dashboard/components/ui/Banner.vue';
import { getTypingUsersText } from '../../../helper/commons';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { REPLY_POLICY } from 'shared/constants/links';
import inboxMixin from 'shared/mixins/inboxMixin';
import { calculateScrollTop } from './helpers/scrollTopCalculationHelper';
import { isEscape } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import UserBanner from './WhatsappTemplates/UserBanner';
import Thumbnail from '../Thumbnail';
import ResolveAction from '../../buttons/ResolveAction';
import EmailTranscriptModal from './EmailTranscriptModal';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    ResolveAction,
    Thumbnail,
    UserBanner,
    Message,
    ReplyBox,
    Banner,
    EmailTranscriptModal,
  },
  mixins: [conversationMixin, inboxMixin, eventListenerMixins, alertMixin],
  props: {
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoadingPrevious: true,
      heightBeforeLoad: null,
      conversationPanel: null,
      selectedTweetId: null,
      isPopoutReplyBox: false,
      contactName: 'Amine Machfer',
      showEmailActionsModal: false,
      convToMerge: true,
      loadingMerge: false,
    };
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      listLoadingStatus: 'getAllMessagesLoaded',
      getUnreadCount: 'getUnreadCount',
      loadingChatList: 'getChatListLoadingStatus',
      conversationLastSeen: 'getConversationLastSeen',
      accountId: 'getCurrentAccountId',
    }),
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta.sender.id
      );
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    hasSelectedTweetId() {
      return !!this.selectedTweetId;
    },
    tweetBannerText() {
      return !this.selectedTweetId
        ? this.$t('CONVERSATION.SELECT_A_TWEET_TO_REPLY')
        : `
          ${this.$t('CONVERSATION.REPLYING_TO')}
          ${this.selectedTweet.content}` || '';
    },
    typingUsersList() {
      const userList = this.$store.getters[
        'conversationTypingStatus/getUserList'
      ](this.currentChat.id);
      return userList;
    },
    isAnyoneTyping() {
      const userList = this.typingUsersList;
      return userList.length !== 0;
    },
    typingUserNames() {
      const userList = this.typingUsersList;

      if (this.isAnyoneTyping) {
        const userListAsName = getTypingUsersText(userList);
        return userListAsName;
      }

      return '';
    },
    getMessages() {
      const messages = this.currentChat.messages || [];
      if (this.isAWhatsAppChannel) {
        return filterDuplicateSourceMessages(messages);
      }
      return messages;
    },
    getReadMessages() {
      return this.readMessages(
        this.getMessages,
        this.currentChat.agent_last_seen_at
      );
    },
    getUnReadMessages() {
      return this.unReadMessages(
        this.getMessages,
        this.currentChat.agent_last_seen_at
      );
    },
    shouldShowSpinner() {
      return (
        (this.currentChat && this.currentChat.dataFetched === undefined) ||
        (!this.listLoadingStatus && this.isLoadingPrevious)
      );
    },

    shouldLoadMoreChats() {
      return !this.listLoadingStatus && !this.isLoadingPrevious;
    },

    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },

    isATweet() {
      return this.conversationType === 'tweet';
    },

    hasInstagramStory() {
      return this.conversationType === 'instagram_direct_message';
    },

    selectedTweet() {
      if (this.selectedTweetId) {
        const { messages = [] } = this.currentChat;
        const [selectedMessage] = messages.filter(
          message => message.id === this.selectedTweetId
        );
        return selectedMessage || {};
      }
      return '';
    },
    isRightOrLeftIcon() {
      if (this.isContactPanelOpen) {
        return 'arrow-chevron-right';
      }
      return 'arrow-chevron-left';
    },
    getLastSeenAt() {
      if (this.conversationLastSeen) return this.conversationLastSeen;
      const { contact_last_seen_at: contactLastSeenAt } = this.currentChat;
      return contactLastSeenAt;
    },

    replyWindowBannerMessage() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_CAN_REPLY');
      }
      if (this.isAPIInbox) {
        const { additional_attributes: additionalAttributes = {} } = this.inbox;
        if (additionalAttributes) {
          const {
            agent_reply_time_window_message: agentReplyTimeWindowMessage,
          } = additionalAttributes;
          return agentReplyTimeWindowMessage;
        }
        return '';
      }
      return this.$t('CONVERSATION.CANNOT_REPLY');
    },
    replyWindowLink() {
      if (this.isAWhatsAppChannel) {
        return REPLY_POLICY.FACEBOOK;
      }
      if (!this.isAPIInbox) {
        return REPLY_POLICY.TWILIO_WHATSAPP;
      }
      return '';
    },
    replyWindowLinkText() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.24_HOURS_WINDOW');
      }
      if (!this.isAPIInbox) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_24_HOURS_WINDOW');
      }
      return '';
    },
    unreadMessageCount() {
      return this.currentChat.unread_count;
    },
    shouldShowMergeIcon() {
      return this.convToMerge;
    },
  },

  watch: {
    currentChat(newChat, oldChat) {
      if (newChat.id === oldChat.id) {
        return;
      }
      this.fetchAllAttachmentsFromCurrentChat();
      this.selectedTweetId = null;
    },
  },

  created() {
    bus.$on(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
    bus.$on(BUS_EVENTS.SET_TWEET_REPLY, this.setSelectedTweet);
  },

  mounted() {
    this.addScrollListener();
    this.fetchAllAttachmentsFromCurrentChat();
    this.convToMerge = true;
  },

  beforeDestroy() {
    this.removeBusListeners();
    this.removeScrollListener();
  },

  methods: {
    fetchAllAttachmentsFromCurrentChat() {
      this.$store.dispatch('fetchAllAttachments', this.currentChat.id);
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
    },
    removeBusListeners() {
      bus.$off(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
      bus.$off(BUS_EVENTS.SET_TWEET_REPLY, this.setSelectedTweet);
    },
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'));
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'));
    },
    setSelectedTweet(tweetId) {
      this.selectedTweetId = tweetId;
    },
    onScrollToMessage({ messageId = '' } = {}) {
      this.$nextTick(() => {
          const messageElement = document.getElementById('message' + messageId);
          if (messageElement) {
              messageElement.scrollIntoView({ behavior: 'smooth' });
              this.fetchPreviousMessages();
          }
          else {
              this.scrollToBottom();
          }
      });
      this.makeMessagesRead();
    },
    showPopoutReplyBox() {
      this.isPopoutReplyBox = !this.isPopoutReplyBox;
    },
    closePopoutReplyBox() {
      this.isPopoutReplyBox = false;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.closePopoutReplyBox();
      }
    },
    addScrollListener() {
      this.conversationPanel = this.$el.querySelector('.conversation-panel');
      this.setScrollParams();
      this.conversationPanel.addEventListener('scroll', this.handleScroll);
      this.$nextTick(() => this.scrollToBottom());
      this.isLoadingPrevious = false;
    },
    removeScrollListener() {
      this.conversationPanel.removeEventListener('scroll', this.handleScroll);
    },
    scrollToBottom() {
      let relevantMessages = [];
      if (this.getUnreadCount > 0) {
        // capturing only the unread messages
        relevantMessages = this.conversationPanel.querySelectorAll(
          '.message--unread'
        );
      } else {
        // capturing last message from the messages list
        relevantMessages = Array.from(
          this.conversationPanel.querySelectorAll('.message--read')
        ).slice(-1);
      }
      this.conversationPanel.scrollTop = calculateScrollTop(
        this.conversationPanel.scrollHeight,
        this.$el.scrollHeight,
        relevantMessages
      );
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    setScrollParams() {
      this.heightBeforeLoad = this.conversationPanel.scrollHeight;
      this.scrollTopBeforeLoad = this.conversationPanel.scrollTop;
    },

      async fetchPreviousMessages(scrollTop = 0) {
          this.setScrollParams();
          const shouldLoadMoreMessages =
              this.currentChat.dataFetched === true &&
              !this.listLoadingStatus &&
              !this.isLoadingPrevious;

          if (
              scrollTop < 100 &&
              !this.isLoadingPrevious &&
              shouldLoadMoreMessages
          ) {
              this.isLoadingPrevious = true;
              try {
                  await this.$store.dispatch('fetchPreviousMessages', {
                      conversationId: this.currentChat.id,
                      before: this.currentChat.messages[0].id,
                  });
                  const heightDifference =
                      this.conversationPanel.scrollHeight - this.heightBeforeLoad;
                  this.conversationPanel.scrollTop =
                      this.scrollTopBeforeLoad + heightDifference;
                  this.setScrollParams();
              } catch (error) {
                  // Ignore Error
              } finally {
                  this.isLoadingPrevious = false;
              }
          }
      },

    /*handleScroll(e) {
      this.setScrollParams();

      const dataFetchCheck =
        this.getMessages.dataFetched === true && this.shouldLoadMoreChats;
      if (
        e.target.scrollTop < 100 &&
        !this.isLoadingPrevious &&
        dataFetchCheck
      ) {
        this.isLoadingPrevious = true;
        this.$store
          .dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.getMessages.messages[0].id,
          })
          .then(() => {
            const heightDifference =
              this.conversationPanel.scrollHeight - this.heightBeforeLoad;
            this.conversationPanel.scrollTop =
              this.scrollTopBeforeLoad + heightDifference;
            this.isLoadingPrevious = false;
            this.setScrollParams();
          });
      }
    },*/

    handleScroll(e) {
      bus.$emit(BUS_EVENTS.ON_MESSAGE_LIST_SCROLL);
      this.fetchPreviousMessages(e.target.scrollTop);
    },

    makeMessagesRead() {
      this.$store.dispatch('markMessagesRead', { id: this.currentChat.id });
    },
    removeTweetSelection() {
      this.selectedTweetId = null;
    },
    async fetchWhatsAppMessages() {
      this.isLoadingPrevious = true ;
      try {
        await this.$store.dispatch('fetchWhatsAppMessages', {
          conversationId: 195, /* id whatsApp conversation */
          before: this.currentChat.messages[this.currentChat.messages.length - 1].id + 1,
          /*after: this.currentChat.messages[this.currentChat.messages.length - 1].id,*/
          accountId: this.accountId,
        });
      } catch (error) {
        // Ignore Error
      } finally {
        this.isLoadingPrevious = false;
      }
    },
    async fetchFbMessages() {
      this.isLoadingPrevious = true;
      try {
        await this.$store.dispatch('fetchFbMessages', {
          conversationId: 154, /* id FB conversation */
          before: this.currentChat.messages[this.currentChat.messages.length - 1].id + 1,
          accountId: this.accountId, /* merge whatsApp & FB conv in widget conv */
        });
      } catch (error) {
        // Ignore Error
      } finally {
        this.isLoadingPrevious = false;
      }
    },
    /* merge whatsApp & FB conv in widget conv */
    async fetchWidgetMessages() {
      this.isLoadingPrevious = true;
      try {
        await this.$store.dispatch('fetchWidgetMessages', {
          conversationId: 194, /* id Widget conversation */
          before: this.currentChat.messages[this.currentChat.messages.length - 1].id + 1,
        });
      } catch (error) {
        // Ignore Error
      } finally {
        this.isLoadingPrevious = false;
      }
    },
    toggleGlobalMerge() {
      this.fetchWhatsAppMessages(); /* merge whatsApp conv in widget conv */
      /*this.fetchFbMessages();*/ /* merge whatsApp & FB conv in widget conv */
      this.showAlert(this.$t('CONVERSATION.MERGE_CONVERSATION'));
      this.convToMerge = !this.convToMerge;
    },
    toggleGlobalUnmerge() {
      /*this.fetchFbMessages();*/ /* merge whatsApp conv in FB conv */
      this.fetchWidgetMessages(); /* merge whatsApp & FB conv in widget conv */
      this.showAlert(this.$t('CONVERSATION.UNMERGE_CONVERSATION'));
      this.convToMerge = !this.convToMerge;
    },
  },
};
</script>

<style scoped lang="scss">
.spinner--container {
  min-height: var(--space-jumbo);
}

.view-box.fill-height {
  height: auto;
  flex-grow: 1;
  min-width: 0;
}

.modal-mask {
  &::v-deep {
    .ProseMirror-woot-style {
      max-height: 40rem;
    }

    .reply-box {
      border: 1px solid var(--color-border);
      max-width: 120rem;
      width: 70%;
    }

    .reply-box .reply-box__top {
      position: relative;
      min-height: 44rem;
    }

    .reply-box__top .input {
      min-height: 44rem;
    }

    .emoji-dialog {
      position: fixed;
      left: unset;
      position: absolute;

      &::before {
        transform: rotate(0deg);
        left: var(--space-half);
        bottom: var(--space-minus-slab);
      }
    }
  }
}
.sidebar-toggle__wrap {
  display: flex;
  justify-content: flex-end;

  .sidebar-toggle--button {
    position: fixed;

    top: var(--space-mega);
    z-index: var(--z-index-low);

    background: var(--white);

    padding: inherit 0;
    border-top-left-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border-bottom-left-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border: 1px solid var(--color-border-light);
    border-right: 0;
    box-sizing: border-box;
  }
}

.custom-margin {
  margin-top: 3rem; /* 1.6 */
  margin-right: 1.5rem;
}

.custom-transcriptm {
  margin-top: 3rem; /* 1.6 */
}
</style>
