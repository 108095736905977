<template>
    <div class="contacts-page row">
        <div class="left-wrap" :class="wrapClass">
            <contacts-header
                    :segments-id="segmentsId"
                    :on-search-submit="onSearchSubmit"
                    :on-export-submit="onExportSubmit"
                    this-selected-contact-id=""
                    :on-input-search="onInputSearch"
                    :on-toggle-create="onToggleCreate"
                    :on-toggle-import="onToggleImport"
                    :on-toggle-filter="onToggleFilters"
                    :header-title="pageTitle"
                    @on-toggle-save-filter="onToggleSaveFilters"
                    @on-toggle-delete-filter="onToggleDeleteFilters"
            />
            <div class="d-flex justify-content-end mx-4 my-5">

                <svg  class="mr-3 cursor-pointer" width="36" height="36" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 16C0 8.45753 0 4.68629 2.34315 2.34315C4.68629 0 8.45753 0 16 0H28C35.5425 0 39.3137 0 41.6569 2.34315C44 4.68629 44 8.45753 44 16V28C44 35.5425 44 39.3137 41.6569 41.6569C39.3137 44 35.5425 44 28 44H16C8.45753 44 4.68629 44 2.34315 41.6569C0 39.3137 0 35.5425 0 28V16Z" fill="#EDECF6"/>
                    <path d="M13.3499 12H22.2499C22.9899 12 23.5999 12.61 23.5999 13.35V14.83C23.5999 15.37 23.2599 16.04 22.9299 16.38L20.0299 18.94C19.6299 19.28 19.3599 19.95 19.3599 20.49V23.39C19.3599 23.79 19.0899 24.33 18.7499 24.54L17.8099 25.15C16.9299 25.69 15.7198 25.08 15.7198 24V20.43C15.7198 19.96 15.4499 19.35 15.1799 19.01L12.6199 16.31C12.2799 15.97 12.0099 15.37 12.0099 14.96V13.41C11.9999 12.61 12.6099 12 13.3499 12Z" stroke="#483A9D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 22.0002V25.0002C12 30.0002 14 32.0002 19 32.0002H25C30 32.0002 32 30.0002 32 25.0002V19.0002C32 15.8802 31.22 13.9202 29.41 12.9002C28.9 12.6102 27.88 12.3902 26.95 12.2402" stroke="#483A9D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M23 23H28" stroke="#483A9D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 27H28" stroke="#483A9D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <woot-button
                        color-scheme="primary"
                        class-names="  "
                        icon="add-circle"
                        class="float-right mb-5"

                        style="height: 36px !important"
                >
                    {{ $t('CREATE_CONTACT.BUTTON_LABEL') }}
                </woot-button>
            </div>
            <contacts-table
                    :contacts="records"
                    :show-search-empty-state="showEmptySearchResult"
                    :is-loading="uiFlags.isFetching"

                    :active-contact-id="selectedContactId"
                    @on-sort-change="onSortChange"
            />
            <table-footer
                    :current-page="Number(meta.currentPage)"
                    :total-count="total === 0 ? meta.count : total"
                    :page-size="size"
                    @page-change="onPageChange"
            />
        </div>

        <add-custom-views
                v-if="showAddSegmentsModal"
                :custom-views-query="segmentsQuery"
                :filter-type="filterType"
                :open-last-saved-item="openSavedItemInSegment"
                @close="onCloseAddSegmentsModal"
        />
        <delete-custom-views
                v-if="showDeleteSegmentsModal"
                :show-delete-popup.sync="showDeleteSegmentsModal"
                :active-custom-view="activeSegment"
                :custom-views-id="segmentsId"
                :active-filter-type="filterType"
                :open-last-item-after-delete="openLastItemAfterDeleteInSegment"
                @close="onCloseDeleteSegmentsModal"
        />

        <!--    <contact-info-panel
              v-if="showContactViewPane"
              :contact="selectedContact"
              :on-close="closeContactInfoPanel"
              @delete="fetchContacts(1)"
            />-->
        <create-contact :show="showCreateModal" @success="onIncrementMeta" @cancel="onToggleCreate" />
        <woot-modal :show.sync="showImportModal" :on-close="onToggleImport">
            <import-contacts v-if="showImportModal" :on-close="onToggleImport" />
        </woot-modal>
        <woot-modal
                :show.sync="showFiltersModal"
                :on-close="closeAdvanceFiltersModal"
                size="medium"
        >
            <contacts-advanced-filters
                    v-if="showFiltersModal"
                    :on-close="closeAdvanceFiltersModal"
                    :initial-filter-types="contactFilterItems"
                    :initial-applied-filters="appliedFilter"
                    :active-segment-name="activeSegmentName"
                    :is-segments-view="hasActiveSegments"
                    @applyFilter="onApplyFilter"
                    @updateSegment="onUpdateSegment"
                    @clearFilters="clearFilters"
            />
        </woot-modal>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex' ;
import { mapState } from 'vuex' ;
import ContactsHeader from './Header';
import ContactsTable from './ContactsTable';
import ContactInfoPanel from './ContactInfoPanel';
import CreateContact from 'dashboard/routes/dashboard/conversation/contact/CreateContact';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import ImportContacts from './ImportContacts.vue';
import ContactsAdvancedFilters from './ContactsAdvancedFilters.vue';
import contactFilterItems from '../contactFilterItems';
import filterQueryGenerator from '../../../../helper/filterQueryGenerator';
import AddCustomViews from 'dashboard/routes/dashboard/customviews/AddCustomViews';
import DeleteCustomViews from 'dashboard/routes/dashboard/customviews/DeleteCustomViews';
import Button from "../../../../components/buttons/Button";
import alertMixin from 'shared/mixins/alertMixin';
import countries from 'shared/constants/countries.js';
import { generateValuesForEditCustomViews } from 'dashboard/helper/customViewsHelper';

const DEFAULT_PAGE = 1;
const FILTER_TYPE_CONTACT = 1;

export default {
    components: {
        Button,
        ContactsHeader,
        ContactsTable,
        TableFooter,
        ContactInfoPanel,
        CreateContact,
        ImportContacts,
        ContactsAdvancedFilters,
        AddCustomViews,
        DeleteCustomViews,
    },
    mixins: [alertMixin],
    props: {
        label: { type: String, default: '' },
        segmentsId: {
            type: [String, Number],
            default: 0,
        },
    },
    data() {
        return {
            showCreateModal: false,
            showImportModal: false,
            selectedContactId: '',
            sortConfig: { last_activity_at: 'desc' },
            showFiltersModal: false,
            contactFilterItems: contactFilterItems.map(filter => ({
                ...filter,
                attributeName: this.$t(
                    `CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`
                ),
            })),
            segmentsQuery: {},
            filterType: FILTER_TYPE_CONTACT,
            showAddSegmentsModal: false,
            showDeleteSegmentsModal: false,
            size: 15, /* display 5 contacts per page*/
            total: 0,
        };
    },
    computed: {
        ...mapGetters({
            records: 'contacts/getContacts',
            uiFlags: 'contacts/getUIFlags',
            meta: 'contacts/getMeta',
            segments: 'customViews/getCustomViews',
            getAppliedContactFilters: 'contacts/getAppliedContactFilters',
        }),
        ...mapState(['clickedLabel', "searchQuery"]),
        showEmptySearchResult() {
            const hasEmptyResults = !!this.searchQuery && this.records.length === 0;
            return hasEmptyResults;
        },
        hasAppliedFilters() {
            return this.getAppliedContactFilters.length;
        },
        hasActiveSegments() {
            return this.activeSegment && this.segmentsId !== 0;
        },
        isContactAndLabelDashboard() {
            return (
                this.$route.name === 'contacts_dashboard' ||
                this.$route.name === 'contacts_labels_dashboard'
            );
        },
        pageTitle() {
            if (this.hasActiveSegments) {
                return this.activeSegment.name;
            }
            if (this.label) {
                return `#${this.label}`;
            }
            return this.$t('CONTACTS_PAGE.HEADER');
        },
        selectedContact() {
            if (this.selectedContactId) {
                const contact = this.records.find(
                    item => this.selectedContactId === item.id
                );
                return contact;
            }
            return undefined;
        },
        showContactViewPane() {
            return this.selectedContactId !== '';
        },
        wrapClass() {
            return this.showContactViewPane ? 'medium-9' : 'medium-12';
        },
        pageParameter() {
            const selectedPageNumber = Number(this.$route.query?.page);
            return !Number.isNaN(selectedPageNumber) &&
            selectedPageNumber >= DEFAULT_PAGE
                ? selectedPageNumber
                : DEFAULT_PAGE;
        },
        activeSegment() {
            if (this.segmentsId) {
                const [firstValue] = this.segments.filter(
                    view => view.id === Number(this.segmentsId)
                );
                return firstValue;
            }
            return undefined;
        },
        activeSegmentName() {
            return this.activeSegment?.name;
        },
    },
    watch: {
        label() {
            this.fetchContacts(DEFAULT_PAGE);
            if (this.hasAppliedFilters) {
                this.clearFilters();
            }
        },
        activeSegment() {
            if (this.hasActiveSegments) {
                const payload = this.activeSegment.query;
                this.fetchSavedFilteredContact(payload, DEFAULT_PAGE);
            }
            if (this.hasAppliedFilters && this.$route.name === 'contacts_dashboard') {
                this.fetchFilteredContacts(DEFAULT_PAGE);
            } else {
                this.fetchContacts(DEFAULT_PAGE);
            }
        },
        clickedLabel() {
            if (this.clickedLabel === true && this.searchQuery !== '') {
                this.updateSearchQuery(''); /* to clear search field after label click action */
                this.updateClickedLabel(false) ;
            }
        },
    },
    mounted() {
        this.$store.dispatch('contacts/clearContactFilters') ; /* to clear contact filtered */
        this.fetchContacts(this.pageParameter);
    },
    methods: {
        ...mapMutations(['updateClickedLabel', "updateSearchQuery"]),
        updatePageParam(page) {
            window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
        },
        getSortAttribute() {
            let sortAttr = Object.keys(this.sortConfig).reduce((acc, sortKey) => {
                const sortOrder = this.sortConfig[sortKey];
                if (sortOrder) {
                    const sortOrderSign = sortOrder === 'asc' ? '' : '-';
                    return `${sortOrderSign}${sortKey}`;
                }
                return acc;
            }, '');
            if (!sortAttr) {
                this.sortConfig = { last_activity_at: 'desc' };
                sortAttr = '-last_activity_at';
            }
            return sortAttr;
        },
        fetchContacts(page) {
            if (this.isContactAndLabelDashboard) {
                this.updatePageParam(page);
                let value = '';
                if (this.searchQuery.charAt(0) === '+') {
                    value = this.searchQuery.substring(1);
                } else {
                    value = this.searchQuery;
                }
                const requestParams = {
                    page,
                    sortAttr: this.getSortAttribute(),
                    label: this.label,
                };
                if (!value) {
                    this.$store.dispatch('contacts/get', requestParams);
                } else {
                    this.$store.dispatch('contacts/search', {
                        search: encodeURIComponent(value),
                        ...requestParams,
                    });
                }
            }
        },
        fetchSavedFilteredContact(payload, page) {
            if (this.hasActiveSegments) {
                this.updatePageParam(page);
                this.$store.dispatch('contacts/filter', {
                    queryPayload: payload,
                    page,
                });
            }
        },
        fetchFilteredContacts(page) {
            if (this.hasAppliedFilters) {
                const payload = this.segmentsQuery;
                this.updatePageParam(page);
                this.$store.dispatch('contacts/filter', {
                    queryPayload: payload,
                    page,
                });
            }
        },

        onInputSearch(event) {
            const newQuery = event.target.value;
            const refetchAllContacts = !!this.searchQuery && newQuery === '';
            this.updateSearchQuery(newQuery) ;
            if (refetchAllContacts) {
                this.fetchContacts(DEFAULT_PAGE);
            }
        },
        onSearchSubmit() {
            this.selectedContactId = '';
            if (this.searchQuery) {
                this.fetchContacts(DEFAULT_PAGE);
            }
        },
        onPageChange(page) {
            this.selectedContactId = '';
            if (this.segmentsId !== 0) {
                const payload = this.activeSegment.query;
                this.fetchSavedFilteredContact(payload, page);
            }
            if (this.hasAppliedFilters) {
                this.fetchFilteredContacts(page);
            } else {
                this.fetchContacts(page);
            }
        },
        openContactInfoPanel(contactId) {
            this.selectedContactId = contactId;
            this.showContactInfoPanelPane = true;
        },
        closeContactInfoPanel() {
            this.selectedContactId = '';
            this.showContactInfoPanelPane = false;
        },
        onToggleCreate() {
            this.showCreateModal = !this.showCreateModal;
        },
        onToggleSaveFilters() {
            this.showAddSegmentsModal = true;
        },
        onCloseAddSegmentsModal() {
            this.showAddSegmentsModal = false;
        },
        onToggleDeleteFilters() {
            this.showDeleteSegmentsModal = true;
        },
        onCloseDeleteSegmentsModal() {
            this.showDeleteSegmentsModal = false;
        },
        onToggleImport() {
            this.showImportModal = !this.showImportModal;
        },
        onSortChange(params) {
            this.sortConfig = params;
            this.fetchContacts(this.meta.currentPage);

            const sortBy =
                Object.entries(params).find(pair => Boolean(pair[1])) || [];

            this.$track(CONTACTS_EVENTS.APPLY_SORT, {
                appliedOn: sortBy[0],
                order: sortBy[1],
            });
        },
        onToggleFilters() {
            if (this.hasActiveSegments) {
                this.initializeSegmentToFilterModal(this.activeSegment);
            }
            this.showFiltersModal = true;
        },
        closeAdvanceFiltersModal() {
            this.showFiltersModal = false;
            this.appliedFilter = [];
        },
        onApplyFilter(payload) {
            this.updateSearchQuery('') ; /* to clear search field after filter action */
            this.closeContactInfoPanel();
            this.segmentsQuery = filterQueryGenerator(payload);
            this.$store.dispatch('contacts/filter', {
                queryPayload: filterQueryGenerator(payload),
            });
            this.showFiltersModal = false;
        },
        onUpdateSegment(payload, segmentName) {
            const payloadData = {
                ...this.activeSegment,
                name: segmentName,
                query: filterQueryGenerator(payload),
            };
            this.$store.dispatch('customViews/update', payloadData);
            this.closeAdvanceFiltersModal();
        },
        clearFilters() {
            this.$store.dispatch('contacts/clearContactFilters');
            this.fetchContacts(this.pageParameter);
            this.total = 0; /* with all records number */
        },
        onExportSubmit() {
            try {
                this.$store.dispatch('contacts/export');
                this.showAlert(this.$t('EXPORT_CONTACTS.SUCCESS_MESSAGE'));
            } catch (error) {
                this.showAlert(
                    error.message || this.$t('EXPORT_CONTACTS.ERROR_MESSAGE')
                );
            }
        },
        openSavedItemInSegment() {
            const lastItemInSegments = this.segments[this.segments.length - 1];
            const lastItemId = lastItemInSegments.id;
            this.$router.push({
                name: 'contacts_segments_dashboard',
                params: { id: lastItemId },
            });
        },
        openLastItemAfterDeleteInSegment() {
            if (this.segments.length > 0) {
                this.openSavedItemInSegment();
            } else {
                this.$router.push({ name: 'contacts_dashboard' });
                this.fetchContacts(DEFAULT_PAGE);
            }
        },
        setParamsForEditSegmentModal() {
            // Here we are setting the params for edit segment modal to show the existing values.

            // For custom attributes we get only attribute key.
            // So we are mapping it to find the input type of the attribute to show in the edit segment modal.
            const params = {
                countries: countries,
                filterTypes: contactFilterItems,
                allCustomAttributes: this.$store.getters[
                    'attributes/getAttributesByModel'
                    ]('contact_attribute'),
            };
            return params;
        },
        initializeSegmentToFilterModal(activeSegment) {
            // Here we are setting the params for edit segment modal.
            //  To show the existing values. when we click on edit segment button.

            // Here we get the query from the active segment.
            // And we are mapping the query to the actual values.
            // To show in the edit segment modal by the help of generateValuesForEditCustomViews helper.
            const query = activeSegment?.query?.payload;
            if (!Array.isArray(query)) return;

            this.appliedFilter.push(
                ...query.map(filter => ({
                    attribute_key: filter.attribute_key,
                    attribute_model: filter.attribute_model,
                    filter_operator: filter.filter_operator,
                    values: Array.isArray(filter.values)
                        ? generateValuesForEditCustomViews(
                            filter,
                            this.setParamsForEditSegmentModal()
                        )
                        : [],
                    query_operator: filter.query_operator,
                    custom_attribute_type: filter.custom_attribute_type,
                }))
            );
        },
        onIncrementMeta() {
            this.onToggleCreate();
            this.fetchContacts(1);
        }
    },
};
</script>

<style lang="scss" scoped>
.contacts-page {
  width: 100%;
}

.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bg-custom{
  background-color: #F9F9F9 !important;
}
</style>
